import { Tabs, TabsContent, TabsList } from '@radix-ui/react-tabs';
import { Link, useNavigate } from '@tanstack/react-router';
import {
  ColumnDef,
  getCoreRowModel,
  useReactTable,
} from '@tanstack/react-table';
import { DateTime } from 'luxon';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  Badge,
  Button,
  CurrencyFlag,
  DataTable,
  Label,
  PageWrapper,
  Skeleton,
  Switch,
  TabsTrigger,
  TransactionStatusBadge,
} from '@agyt/client/shared/ui/components';
import {
  IconLayoutGrid,
  IconList,
  IconPlusCircle,
} from '@agyt/client/shared/ui/icons';
import { useUser } from '@agyt/client/web/core/user';
import {
  useBalances,
  useFindTransactions,
} from '@agyt/client/web/data-access/api';
import { AccountCard, AccountListItem } from '@agyt/client/web/ui/components';
import {
  TransactionListResponse,
  TransactionResponse,
} from '@agyt/shared/types';
import { LocalDate, Money } from '@agyt/shared/util/common';

function EmptyListView() {
  const { t } = useTranslation('accountCurrencies');
  return (
    <section className="flex h-[436px] w-full flex-col items-center justify-center rounded-lg border border-slate-200 bg-white p-4">
      <div className="flex max-w-min flex-col items-center justify-center">
        <h2 className="text-nowrap text-xl font-medium text-slate-900">
          {t('empty.title')}
        </h2>
        <p className="mt-2.5 text-center leading-7 text-slate-500">
          {t('empty.subtitle')}
        </p>
        <Link to="/account-currencies/new">
          <Button variant="subtle" className="mt-8">
            <IconPlusCircle />
            <span className="ml-2">{t('actions.add')}</span>
          </Button>
        </Link>
      </div>
    </section>
  );
}

function PageSkeleton() {
  return (
    <div className="mt-5 flex flex-col gap-5">
      <div className="flex w-full items-center justify-between">
        <Skeleton className="h-10 w-36 rounded-md" />
        <Skeleton className="h-10 w-36 rounded-md" />
      </div>
      <div className="grid grid-cols-3 gap-6">
        {Array.from({ length: 6 }, (_, index) => index).map((index) => (
          <Skeleton className="h-40 rounded-md" key={index} />
        ))}
      </div>
    </div>
  );
}

function GridSkeleton() {
  return (
    <div className="mt-5 grid grid-cols-3 gap-6">
      {Array.from({ length: 6 }, (_, index) => index).map((index) => (
        <Skeleton className="h-40 rounded-md" key={index} />
      ))}
    </div>
  );
}

function ListSkeleton() {
  return (
    <div className="grid grid-cols-1 gap-2">
      {Array.from({ length: 6 }, (_, index) => index).map((index) => (
        <Skeleton className="h-[77px] w-full rounded-md" key={index} />
      ))}
    </div>
  );
}

function TableSkeleton() {
  return (
    <div className="mt-10 flex flex-col gap-5">
      <Skeleton className="h-10 w-44 rounded-md" />
      {Array.from({ length: 6 }, (_, index) => index).map((index) => (
        <Skeleton className="h-10 w-full rounded-md" key={index} />
      ))}
    </div>
  );
}

export function AccountCurrenciesPage() {
  const { t } = useTranslation('accountCurrencies');
  const { locale } = useUser();
  const navigate = useNavigate({ from: '/account-currencies' });
  const [showAll, setShowAll] = useState(true);
  const {
    data: balancesData,
    refetch: fetchBalances,
    isFetching: isFetchingBalances,
    isLoading: isLoadingBalances,
  } = useBalances(showAll);
  const {
    data: transactionsData,
    refetch: fetchTransactions,
    isFetching: isFetchingTransactions,
  } = useFindTransactions({});
  const [transactions, setTransactions] = useState<TransactionResponse[]>([]);
  const accountCurrencies = balancesData?.data.balances;

  const sortedCurrencies = accountCurrencies?.sort((a, b) => {
    if (a.amount === '0.00' && b.amount !== '0.00') {
      return 1;
    }
    if (a.amount !== '0.00' && b.amount === '0.00') {
      return -1;
    }
    return 0;
  });

  useEffect(() => {
    fetchBalances();
  }, [fetchBalances, showAll]);

  useEffect(() => {
    fetchTransactions();
  }, []);

  useEffect(() => {
    if (transactionsData) {
      setTransactions(
        transactionsData.data
          .map((transaction) => ({
            ...transaction,
            credit: '',
            debit: '',
          }))
          .slice(0, 3),
      );
    }
  }, [transactionsData]);

  const columns: ColumnDef<TransactionListResponse>[] = [
    {
      accessorKey: 'createdAt',
      header: t('table.columns.date', { ns: 'transactions' }),
      enableGlobalFilter: true,
      cell: ({ row: { original } }) => (
        <div>
          {new LocalDate({
            timestamp: original.createdAt,
            locale,
          }).format(DateTime.DATE_SHORT)}
        </div>
      ),
    },
    {
      accessorKey: 'action',
      header: t('table.columns.action', { ns: 'transactions' }),
      enableGlobalFilter: true,
      cell: ({ row: { original } }) => {
        return (
          <div className="flex flex-col">
            <div>
              {t(`values.action.${original.action}`, {
                ns: 'transactions:details',
              })}

              {original.action === 'payment' && original.paymentType && (
                <span>
                  &nbsp;(
                  {t(`values.paymentType.${original.paymentType}`, {
                    ns: 'pay',
                  })}
                  )
                </span>
              )}
            </div>
            {original.action === 'payment' &&
              (
                transactionsData?.meta as {
                  beneficiaries: { [key: string]: string };
                }
              )?.beneficiaries[original.relatedEntityId] && (
                <div className="text-slate-500">
                  {t('labels.to')}:&nbsp;
                  {
                    (
                      transactionsData?.meta as {
                        beneficiaries: { [key: string]: string };
                      }
                    )?.beneficiaries[original.relatedEntityId]
                  }
                </div>
              )}
          </div>
        );
      },
    },
    {
      accessorKey: 'relatedEntityShortReference',
      header: t('table.columns.reference', { ns: 'transactions' }),
      enableGlobalFilter: true,
      cell: ({ row: { original } }) => {
        return original.action === 'payment'
          ? (original.paymentReference ?? original.relatedEntityShortReference)
          : original.relatedEntityShortReference;
      },
    },
    {
      accessorKey: 'credit',
      header: t('table.columns.credit', { ns: 'transactions' }),
      enableGlobalFilter: true,
      cell: ({ row: { original } }) => {
        if (original.type === 'credit') {
          const converted = Number(original.amount);
          const credit = new Money({
            amount: !isNaN(converted) ? converted : 0,
            currency: original.currency,
            locale,
          }).format();
          return <div>{credit}</div>;
        }
        return '';
      },
    },
    {
      accessorKey: 'debit',
      header: t('table.columns.debit', { ns: 'transactions' }),
      enableGlobalFilter: true,
      cell: ({ row: { original } }) => {
        if (original.type === 'debit') {
          const converted = Number(original.amount);
          const debit = new Money({
            amount: !isNaN(converted) ? converted : 0,
            currency: original.currency,
            locale,
          }).format();
          return <div>{debit}</div>;
        }
        return '';
      },
    },
    {
      accessorKey: 'balanceAmount',
      header: t('table.columns.balance', { ns: 'transactions' }),
      enableGlobalFilter: true,
      cell: ({ row: { original } }) => {
        const converted = Number(original.balanceAmount);
        const balance = new Money({
          amount: !isNaN(converted) ? converted : 0,
          currency: original.currency,
          locale,
        }).format();
        return <div>{balance}</div>;
      },
    },
    {
      accessorKey: 'status',
      header: t('table.columns.status', { ns: 'transactions' }),
      enableGlobalFilter: true,
      cell: ({ row: { original } }) => (
        <TransactionStatusBadge status={original.status} />
      ),
    },
  ];

  const table = useReactTable({
    data: transactions || [],
    columns,
    getCoreRowModel: getCoreRowModel(),
  });

  return (
    <PageWrapper>
      <header className="flex items-center justify-between">
        <div className="flex items-center gap-2">
          <h1 className="text-3xl font-medium">{t('title')}</h1>
          <span>
            {isFetchingBalances ? (
              <Skeleton className="h-6 w-8" />
            ) : (
              !!sortedCurrencies?.length && (
                <Badge className="h-6 rounded-sm py-0.5">
                  {sortedCurrencies.length}
                </Badge>
              )
            )}
          </span>
        </div>
        <Link to="/account-currencies/new">
          <Button>
            <IconPlusCircle />
            <span className="ml-2">{t('actions.add')}</span>
          </Button>
        </Link>
      </header>
      <div className="mt-10">
        {isLoadingBalances ? (
          <PageSkeleton />
        ) : (
          <div>
            <div>
              <Tabs defaultValue="grid">
                <div className="flex w-full items-start justify-between">
                  <TabsList className="mb-6 rounded-sm bg-slate-200 p-1">
                    <TabsTrigger value="grid">
                      <IconLayoutGrid className="mr-1 h-4 w-4" />{' '}
                      {t('labels.grid')}
                    </TabsTrigger>
                    <TabsTrigger value="list">
                      <IconList className="mr-1 h-4 w-4" />
                      {t('labels.list')}
                    </TabsTrigger>
                  </TabsList>
                  {/* TODO: Change to hide all */}
                  {/* <div className="flex items-center space-x-2">
                    <Switch
                      id="showAll"
                      checked={showAll}
                      onCheckedChange={() => setShowAll(!showAll)}
                    />
                    <Label htmlFor="airplane-mode">
                      {t('labels.show_all')}
                    </Label>
                  </div> */}
                </div>
                {sortedCurrencies?.length ? (
                  <>
                    <TabsContent value="grid">
                      {isFetchingBalances ? (
                        <GridSkeleton />
                      ) : sortedCurrencies?.length ? (
                        <section className="flex flex-wrap gap-6">
                          {sortedCurrencies.map((balance: any) => (
                            <AccountCard
                              key={balance.id}
                              name={`${balance.currency} ${t('labels.account')}`}
                              balance={new Money({
                                amount: balance.amount,
                                currency: balance.currency,
                                locale,
                              }).format()}
                              currency={balance.currency}
                              returnTo="account-currencies"
                            />
                          ))}
                        </section>
                      ) : null}
                    </TabsContent>
                    <TabsContent value="list">
                      {isFetchingBalances ? (
                        <ListSkeleton />
                      ) : sortedCurrencies?.length ? (
                        <section className="grid grid-cols-1 gap-2">
                          {sortedCurrencies.map((balance: any) => (
                            <AccountListItem
                              key={balance.id}
                              name={`${balance.currency} ${t('account', { ns: 'dashboard' })}`}
                              balance={new Money({
                                amount: balance.amount,
                                currency: balance.currency,
                                locale,
                              }).format()}
                              currency={balance.currency}
                              returnTo="account-currencies"
                            >
                              <CurrencyFlag
                                currency={balance.currency}
                                alt={balance.currency}
                                className="h-[45px] w-[45px]"
                              />
                            </AccountListItem>
                          ))}
                        </section>
                      ) : null}
                    </TabsContent>
                  </>
                ) : (
                  <EmptyListView />
                )}
              </Tabs>
            </div>

            {isFetchingTransactions ? (
              <TableSkeleton />
            ) : (
              <div className="mt-12">
                <div className="flex w-full justify-between">
                  <div className="text-xl font-medium text-slate-900">
                    {t('labels.latest_transactions')}
                  </div>
                  <Button variant="ghost" className="text-blue-500">
                    <Link to="/transactions">{t('actions.seeAll')}</Link>
                  </Button>
                </div>
                <DataTable<TransactionResponse>
                  table={table}
                  onRowClick={({ id }) => {
                    navigate({
                      to: '/transactions/$id',
                      params: { id },
                    });
                  }}
                />
              </div>
            )}
          </div>
        )}
      </div>
    </PageWrapper>
  );
}

export default AccountCurrenciesPage;
