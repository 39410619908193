import { useAuth0 } from '@auth0/auth0-react';
import axios, { AxiosInstance } from 'axios';
import { createContext, PropsWithChildren, useContext } from 'react';

const apiUrl = process.env.NX_PUBLIC_API_URL;

if (!apiUrl) {
  throw new Error('API URL is not set');
}

const httpClient = axios.create({
  baseURL: apiUrl,
  timeout: 5000,
  headers: {
    accepts: 'application/json',
    'content-type': 'application/json',
  },
  validateStatus: (status) => status < 400,
});

interface HttpClientProvider {
  httpClient: AxiosInstance;
}

export const ApiClientContext = createContext<HttpClientProvider | undefined>(
  undefined,
);

export function HttpClientProvider({ children }: PropsWithChildren) {
  const { getAccessTokenSilently } = useAuth0();

  httpClient.interceptors.request.use(
    async (config) => {
      try {
        const accessToken = await getAccessTokenSilently({
          cacheMode: 'cache-only',
        });
        if (!accessToken) {
          return Promise.reject('no token');
        }

        config.headers.Authorization = `Bearer ${accessToken}`;
        return config;
      } catch (err) {
        return Promise.reject(err);
      }
    },
    (error) => {
      return Promise.reject(error);
    },
  );

  return (
    <ApiClientContext.Provider value={{ httpClient }}>
      {children}
    </ApiClientContext.Provider>
  );
}

export const useHttpClient = () => {
  const context = useContext(ApiClientContext);

  if (!context) {
    throw new Error('useHttpClient must be used inside the HttpClientProvider');
  }

  return context;
};
